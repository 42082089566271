/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useMe } from "../../hooks/queries";

import "./styles.scss";

export interface ILeftMenuProps {
  onChangeExpand: () => void;
}

export const LeftMenu: React.FunctionComponent<ILeftMenuProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`leftMenu.${key}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChangeExpand } = props;

  // TODO: Fix me
  const { data: me } = useMe();

  const isOrgUser = () => {
    return me?.type === "org_admin";
  };

  const isAdminUser = () => {
    return me?.type === "admin";
  };

  return (
    <div className="left-nav">
      {/* TODO:
      <a className="icons top-arrow"
        onClick={() => {
          onChangeExpand()
        }}>
      </a>
      */}
      <a className="icons top-arrow"></a>
      <ul>
        {me && isAdminUser() && (
          <>
            <li>
              <NavLink to="/admin/locations" className={`nav-items`}>
                <i className="icons icon-build"></i>
                <span className="txt">{t("facility_management")}</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/admin/organizations" className={`nav-items`}>
                <i className="icons icon-user-circle"></i>
                <span className="txt">{t("organization_management")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/internalTools" className={`nav-items`}>
                <i className="icons icon-user-circle"></i>
                <span className="txt">{t("internal_tools")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/deleteGoogleLink" className={`nav-items`}>
                <i className="icons icon-trash"></i>
                <span className="txt">{t("delete_google_link")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/massSendEmails" className={`nav-items`}>
                <i className="icons icon-email"></i>
                <span className="txt">{t("email_users")}</span>
              </NavLink>
            </li>
          </>
        )}
        {me && !isAdminUser() && (
          <>
            {isOrgUser() && (
              <li>
                <NavLink to="/dashboard" className={`nav-items`}>
                  <i className="icons icon-dashboard"></i>
                  <span className="txt">{t("dashboard")}</span>
                </NavLink>
              </li>
            )}

            {isOrgUser() && (
              <li>
                <NavLink
                  to={`/analysis/organization/${me.organization.id}/collections`}
                  className={`nav-items`}
                >
                  <i className="icons icon-chart"></i>
                  <span className="txt">{t("analysis")}</span>
                </NavLink>
              </li>
            )}

            <li>
              <NavLink
                to={
                  me.organization?.id
                    ? `/locations/organization/${me.organization.id}`
                    : "/locations"
                }
                className={`nav-items`}
              >
                <i className="icons icon-build"></i>
                <span className="txt">{t("facility_list")}</span>
              </NavLink>
            </li>

            {isOrgUser() && (
              <li>
                <NavLink to="/recommendations" className={`nav-items`}>
                  <i className="icons icon-suggestions"></i>
                  <span className="txt">{t("suggestions")}</span>
                </NavLink>
              </li>
            )}

            {isOrgUser() && (
              <li>
                <NavLink to="/recMessages" className={`nav-items`}>
                  <i className="icons icon-email"></i>
                  <span className="txt">{t("rec_messages")}</span>
                </NavLink>
              </li>
            )}

            {isOrgUser() && (
              <li>
                <NavLink to="/orgTools" className={`nav-items`}>
                  <i className="icons icon-setting"></i>
                  <span className="txt">{t("tools")}</span>
                </NavLink>
              </li>
            )}

            <li>
              <NavLink to={`/account/${me.id}`} className={`nav-items`}>
                <i className="icons icon-user-circle"></i>
                <span className="txt">{t("account")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/management/locations" className={`nav-items`}>
                <i className="icons icon-list"></i>
                <span className="txt">{t("integration")}</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default LeftMenu;
