import { META } from "./config";
import AccountManagementPage from "./containers/AccountManagementPage";
import AdminAccountManagementPage from "./containers/AdminAccountManagementPage";
import AnalysisCollectionsPage from "./containers/AnalysisCollectionsPage";
import DashboardPage from "./containers/DashboardPage";
import DeleteGoogleLinkPage from "./containers/DeleteGoogleLinkPage";
import FacilityDetailsPage from "./containers/FacilityDetailsPage";
import FacilityListPage from "./containers/FacilityListPage";
import FacilityManagementPage from "./containers/FacilityManagementPage";
import FacilityRegistrationPage from "./containers/FacilityRegistrationPage";
import InternalToolsPage from "./containers/InternalToolsPage";
import MassSendEmailsPage from "./containers/MassSendEmailsPage";
import OrganizationManagementPage from "./containers/OrganizationManagementPage";
import OrgToolsPage from "./containers/OrgToolsPage";
import RecMessagesListPage from "./containers/RecMessagesListPage";
import RootPage from "./containers/RootPage";
import SuggestionsListPage from "./containers/SuggestionsListPage";

/**
 * Generate an object with all necessary fields to render a page.
 * @param {string} path - The page path
 * @param {string} title - THe page title (for SEO)
 * @param {Function} component - The component to be rendered. Containers can also be used
 * @param {string} description - The page description (for SEO) [OPTIONAL]
 * @param {string} keywords - The comma separated page keywords (for SEO) [OPTIONAL]
 * @returns {object}
 */
export const createPage = (
  path: any,
  title: any,
  component: any,
  description?: any,
  keywords?: any
) => ({
  path,
  title: `${title} | ${META.PAGE_TITLE_SUFFIX}`,
  component,
  description: description || META.PAGE_DESCRIPTION,
  keywords: keywords || META.PAGE_KEYWORDS,
});

const exportRoute = [
  createPage("/", "RootPage", RootPage),
  createPage("/dashboard", "DashboardPage", DashboardPage),
  createPage(
    "/dashboard/organization/:organizationId",
    "DashboardPage",
    DashboardPage
  ),
  createPage(
    "/dashboard/organization/:organizationId/customDashboard/:customDashboardId",
    "DashboardPage",
    DashboardPage
  ),
  createPage("/locations", "FacilityListPage", FacilityListPage),
  createPage(
    "/locations/organization/:organizationId",
    "FacilityListPage",
    FacilityListPage
  ),
  createPage(
    "/location/:locationId",
    "FacilityDetailsPage",
    FacilityDetailsPage
  ),
  createPage(
    "/location/:locationId/organization/:organizationId",
    "FacilityDetailsPage",
    FacilityDetailsPage
  ),
  createPage("/recommendations", "SuggestionsListPage", SuggestionsListPage),
  createPage(
    "/recommendations/organization/:organizationId",
    "SuggestionsListPage",
    SuggestionsListPage
  ),
  createPage(
    "/recommendations/organization/:organizationId/:generatedDate",
    "SuggestionsListPage",
    SuggestionsListPage
  ),
  createPage("/recMessages/", "RecMessagesListPage", RecMessagesListPage),
  createPage(
    "/recMessages/organization/:organizationId",
    "RecMessagesListPage",
    RecMessagesListPage
  ),
  createPage("/orgTools/", "OrgToolsPage", OrgToolsPage),
  createPage(
    "/management/locations",
    "FacilityRegistrationPage",
    FacilityRegistrationPage
  ),
  createPage(
    "/analysis/collections",
    "AnalysisCollectionsPage",
    AnalysisCollectionsPage
  ),
  createPage(
    "/analysis/organization/:organizationId/collections",
    "AnalysisCollectionsPage",
    AnalysisCollectionsPage
  ),
  createPage("/accounts", "AccountManagementPage", AccountManagementPage),
  createPage(
    "/account/:accountId",
    "AccountManagementPage",
    AccountManagementPage
  ),

  createPage(
    "/admin/locations",
    "FacilityManagementPage",
    FacilityManagementPage
  ),
  createPage(
    "/admin/organizations",
    "OrganizationManagementPage",
    OrganizationManagementPage
  ),
  createPage("/admin/internalTools", "InternalToolsPage", InternalToolsPage),
  createPage(
    "/admin/organizations/:organizationId/accounts",
    "AdminAccountManagementPage",
    AdminAccountManagementPage
  ),
  createPage(
    "/admin/deleteGoogleLink",
    "DeleteGoogleLinkPage",
    DeleteGoogleLinkPage
  ),
  createPage("/admin/massSendEmails", "MassSendEmailsPage", MassSendEmailsPage),
];

export default exportRoute;
