import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

import { LocalPostData } from "../../../models/LocalPostData";
import { LocalPostItem } from "../LocalPostsComponent/LocalPostItem";

export interface IRecentMediaListProps {
  dataList: LocalPostData[] | null;
  isShowLocationName?: boolean;
}

export const RecentPostList: React.FunctionComponent<IRecentMediaListProps> = (
  props
) => {
  const { dataList, isShowLocationName } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.localPostList.${key}`);

  return (
    <div className="recent-local-post-list">
      <div className="local-post-list">
        {dataList !== null && dataList.length > 0 ? (
          dataList.map((data: LocalPostData, index: number) => (
            <LocalPostItem
              key={index}
              localPostItem={data}
              isShowLocationName={isShowLocationName}
            />
          ))
        ) : (
          <small className="no-data">{t("no_data")}</small>
        )}
      </div>
    </div>
  );
};

export default RecentPostList;
