import dayjs from "dayjs";
import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

import { LocalPostData } from "../../../../models/LocalPostData";

export interface ILocalPostItemProps {
  localPostItem: LocalPostData;
  isShowLocationName?: boolean;
}

export const LocalPostItem: React.FunctionComponent<ILocalPostItemProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.localPostItem.${key}`);
  const { localPostItem, isShowLocationName = true } = props;

  return (
    <a
      className="local-post-item"
      key={localPostItem.id}
      href={localPostItem?.searchUrl}
      target="_blank"
      rel="noreferrer"
    >
      <div className="local-post-top-info">
        <div className="left-title">
          {isShowLocationName ? localPostItem.location_name : ""}
        </div>
        <div className="right-title">
          {dayjs(localPostItem.post_date).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="local-post-bottom-container">
        <div className="local-post-img-container">
          {localPostItem.media ? (
            <img
              className="local-post-img"
              alt="local-post"
              src={localPostItem?.media[0]?.googleUrl || ""}
            />
          ) : (
            <div className="no-image">
              <div>{t("no_picture")}</div>
            </div>
          )}
        </div>
        {localPostItem.summary ? (
          <div className="summary">{localPostItem.summary}</div>
        ) : (
          <div className="no-summary">{t("no_comment")}</div>
        )}
      </div>
    </a>
  );
};

export default LocalPostItem;
